@import 'variables';

.toast-success {
    background-color: $status--ok;
}
.toast-info {
    background-color: $primary;
}
.toast-warning {
    background-color: $status--to-be-monitored;
}
.toast-error {
    background-color: $status--failure;
}
.toast-loading {
    background-image: none;
    position: relative;
    
    &::after {
        content: "downloading";
        font-family: "Material Icons";
        position: absolute;
        top: 4px;
        left: 6px;
        bottom: 0;
        font-size: 32px;
        animation: blink 2.5s infinite;
    }
}

.toast-container {
    &:has(.toast-loading) {
        bottom: 80px
    }
}

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: .1;
    }
}
