@import './styles/variables';
/* G L O B A L  U T I L S */
html {
    font-size: 10px !important;
}
body {
    box-sizing: border-box;
    font-size: 1.4rem;
    padding: 0;
    margin: 0;
    background: $background;
    color: $text;
    scroll-behavior: smooth;
    padding-top: $nav-height;
    line-height: 1.3;
    // fix jumping width between views
    overflow-y: scroll;
    
    &:has(.fullscreen) {
        padding-top: 0px;
    }

    // add this class for fullscreen views (e.g. map)
    &.no-scrolling {
        overflow: hidden;
    }
}
* {
    box-sizing: border-box;
    font-family: "ChesnaGrotesk", Arial, Geneva, Helvetica, sans-serif;
    scroll-behavior: smooth;
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
} 
::-webkit-scrollbar-track {
    width: 7px;
    height: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .3);
}
.container {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    padding-left: 45px;
    padding-right: 45px;
}
.flex-row {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
}
.flex-column {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.no-wrap {
    flex-wrap: nowrap;
}
.overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
label {
    &.form-control {
        display: grid;
        grid-template-columns: 3.2rem auto;
        gap: 0.5rem;
        align-items: center;
    }
}
input {
    &[type="checkbox"] {
        appearance: none;
        background-color: $white;
        margin: 0;
        font: inherit;
        color: currentColor;
        width: 2.7rem;
        height: 2.7rem;
        aspect-ratio: 1;
        border: 2px solid $text;
        border-radius: 6px;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
        transition: border-color .1s ease-in-out;

        height: 20px;
        width: 20px;
        border-radius: 3px;
        border: 2px solid $primary;

        &:hover {
            cursor: pointer;
        }

        &::before {
            content: "check_box";
            font-family: Material Icons;
            font-size: 37px;
            color: $primary;
            transform: scale(0);
            transform-origin: center;
            transition: 120ms transform ease-in-out;
        }

        &.checkbox-lg {
            width: 3.2rem;
            height: 3.2rem;

            &::before {
                width: 2rem;
                height: 2rem;
            }
        }

        &:checked {
            &::before {
                transform: scale(.7);
            }
        }

        &:indeterminate {
            background-color: $primary;
            border-color: $primary;

            &::before {
                content: "remove";
                transform: scale(.5);
                color: white;
            }
        }

        &:disabled,
        &.disabled-selectable {
            border: 1px solid $grey--strong !important;

            &:checked {
                background-color: $grey--strong !important;
            }

            & ~ label {
                opacity: .7;
            }
        }

        &:disabled {
            cursor: default;
            
            & ~ label {
                cursor: default !important;
            }
        }
    }

    &[type="text"],
    &[type="number"] {
        width: 100%;
        font-size: 14px;
        box-sizing: border-box;
        height: 30px;
        border-radius: 1px;
        padding: 4px 8px;
        line-height: 22px;
        outline: none;
        border: none;
        background: $white;
        box-shadow: 0 0 0 1.5px $text;
        color: $text;
        transition: box-shadow .2s ease-in-out;

        &.input-large {
            height: 44px;
        }

        &:focus,
        &:focus-within {
            box-shadow: 0 0 0 1.5px $primary;
        }

        &.ng-invalid:not(.ng-pristine) {
            box-shadow: 0 0 0 1.5px $status--failure !important;
        }
    }

    &[type="number"] {
        appearance: textfield;
        -moz-appearance: textfield;
        font-variant: tabular-nums;

        /* Hide arrow buttons */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    &[type="radio"] {
        appearance: none;
        background-color: #fff;
        margin: 0;
        font: inherit;
        color: currentColor;
        width: 2.7rem;
        height: 2.7rem;
        aspect-ratio: 1;
        border: 2px solid $text;
        border-radius: 50%;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
        cursor: pointer;

        &:checked {
            border: 2px solid $primary;
        }

        &::before {
            content: "";
            background-color: $primary;
            width: 1.7rem;
            height: 1.7rem;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: $box-shadow;
        }

        &:checked::before {
            transform: scale(1);
        }
    }

    &:disabled {
        opacity: .8;    
    }

    &.invalid {
        box-shadow: 0 0 0 1.5px $status--failure !important;
    }
}
.form-group {
    position: relative;
    padding: 9px 2px 14px 2px;

    label {
        position: absolute;
        font-size: 1.4rem;
        line-height: 1.5rem;
        top: 0;
        left: 1.2rem;
        z-index: 1;
        transition: color calc($animation-duration / 2) ease-in-out;
        color: $grey;

        &::before {
            content: "";
            position: absolute;
            display: block;
            left: -0.4rem;
            bottom: 0.5rem;
            width: calc(100% + 8px);
            height: 3px;
            background: $white;
            z-index: -1;
        }
    }

    input {
        &:focus,
        &:focus-visible {
            & ~ label {
                color: $primary;
            }
        }

        &:disabled {
            & ~ * {
                color: $text;
            }
        }
    }

    // grey slot on right side of input
    .input-info {
        position: absolute;
        right: 2px;
        bottom: 14px;
        top: 9px;
        background-color: $background;
        padding: 8px 5px;
        font-size: 1.2rem;
        border-radius: 0 1px 1px 0;
    }

    .input-error {
        color: $status--failure;
        font-size: 1.1rem;
        position: absolute;
        left: 2px;
        right: 0;
        bottom: 0;
    }

    &.loading {
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            top: 9px;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 1px;
            transform: translateX(-100%);
            background-image: linear-gradient(
                90deg,
                rgba(#d5d5d5, 0) 0,
                rgba(#d5d5d5, 0.2) 20%,
                rgba(#d5d5d5, 0.5) 60%,
                rgba(#d5d5d5, 0)
            );
            animation: skeleton ($animation-duration * 8) infinite;
        }
    }
}
button {
    cursor: pointer;

    &.delete-search {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 30px;
        border: none;
        background-color: $primary;
        background-image: none;
        mask-image: url("./assets/icons/close-turqouise.svg");
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: 10px 10px;
        outline: none;
        filter: grayscale(1);
        transition: filter .1s ease-in-out;

        &:hover {
            filter: grayscale(0);
        }
    }

    &.btn-chevron {
        display: block;
        height: 40px;
        width: 40px;
        position: relative;
        outline-color: transparent;
        border: none;
        background: none;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            font-size: 40px;
            line-height: 1;
            text-align: center;
            color: $text;
            transition: color calc($animation-duration / 2) ease-in-out;
            content: "chevron_left";
            font-family: 'Material Icons';
        }

        &.chevron-right {
            &::after {
                content: "chevron_right";
            }
        }

        &:disabled,
        &.disabled {
            cursor: default;

            &::after {
                color: $text--light;
            }
        }
    }

    &.btn-icon {
        border: none;
        outline-color: transparent;
        background: none;
        height: 40px;
        width: 40px;
        text-align: center;
        border-radius: 3px;
        font-size: 32px;
        font-family: 'Material Icons';
        color: $grey--strong;
        transition: color calc($animation-duration / 2) ease-in-out;

        &:active,
        &.active {
            color: $text;
        }
    }
}
.box {
    padding: 2.5rem 3.2rem;
    background-color: $white;
    box-shadow: $box-shadow;
}
.box-small {
    padding: 1.2rem 1.8rem;
    border: 1px double transparent;
    border-radius: 6px;
    background-image: $background-image;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    box-shadow: $box-shadow--panel;
}
.box-border--gradient {
    padding: 2rem;
    border: 1px double transparent;
    border-radius: 6px;
    background-image: $background-image;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    box-shadow: $box-shadow--panel;
}
.button-dropdown {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    border: 1px solid $grey;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: 0 0 0 0px transparent;
    outline: none;
    background: $white;
    padding: 12px;
    position: relative;
    transition: box-shadow $animation-duration ease-in-out;

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        cursor: not-allowed;
        filter: grayscale(.1);
    }

    .button-title {
        position: absolute;
        white-space: nowrap;
        font-size: 1.4rem;
        top: -1.1rem;
        left: 1.2rem;
        z-index: 2;
        color: $grey;

        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            left: -.3rem;
            width: calc(100% + 6px);
            z-index: -1;
        }

        &::before {
            background: $white;
            height: 1.5px;
            bottom: 7px;
        }

        &::after {
            background: $white;
            height: 0px; // height is only set on button focus
            transition: height $animation-duration ease-in-out;
            bottom: 8px;
        }
    }

    .button-desc {
        font-size: 1.8rem;
    }

    &.dropdown-small {
        height: 32px;
        padding: 6px 8px;

        .button-title {
            font-size: 1.2rem;
            line-height: 1.5rem;
            top: -.9rem;
            left: .7rem;

            &::before {
                bottom: 6px;
            }
            &::after {
                bottom: 7px;
            }
        }

        .button-desc {
            font-size: 1.4rem;
        }
    }

    &.dropdown-clean {
        border: 0px;
        border-radius: 7px;
        box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.03);

        .button-desc {
            font-size: 1.3rem;
            font-weight: bold;
        }

        &:focus-visible,
        &:focus {
            box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.02);
        }
    }

    &.dropdown-filter {
        width: fit-content;
        max-width: 100%;
        min-width: 120px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    &.dropdown-kpi-filter {
        width: 230px;
    }

    &.filter-fixed {
        width: 230px;
    }

    &:focus-visible,
    &:focus {
        box-shadow: $box-shadow--focus;

        .button-title {
            &::after {
                height: 2.5px;
            }
        }
    }
}
.delete-filter-button {
    border: none;
    outline: none;
    background: none;
    position: absolute;
    right: -40px;
    top: 9px;
    cursor: pointer;

    .material-icon {
        color: $text;
    }
}
a {
    cursor: pointer;
    color: $text;

    &:hover {
        color: rgba($text, .8);
    }
}
.subline {
    color: $grey--strong;
}
a[href^="https://"] {
    color: $text;
    text-decoration: underline;
}
.cursor-pointer {
    cursor: pointer;
}
 
.dropdown-list {
    list-style: none;
    padding: 0;
    font-size: 18px;

    li {
        padding: 1rem .8rem;
        
        * {
            word-wrap: break-word;
            word-break: break-word;
        }

        &:not(:last-of-type) {
            border-bottom: 1px solid $off-white;
        }

        .list-select {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            user-select: none;

            label {
                padding-left: 8px;
                cursor: pointer;
            }
        }
    }
}
// sortable table headers - one icon for both asc and desc
[data-order] {
    text-align: left;
    position: relative;
    user-select: none;

    &:not(:empty) {
        &:hover {
            cursor: pointer;
        }

        &::after {
            content: "expand_more";
            font-family: "Material Icons";
            color: $primary;
            font-size: 28px;
            display: inline-block;
            position: absolute;
            height: 28px;
            right: 5px;
            top: calc(50% - 15px);
            transform-origin: 50% 63%;
            text-align: center;
            transition: all $animation-duration ease-in-out;
        }

        &[data-order="asc"] {
            &::after {
                transform: rotate(180deg);
            }
        }
        &[data-order="none"] {
            &::after {
                transform: rotate(180deg);
                color: $grey;
            }
        }
    }
}
// sortable table headers - seperate icons for asc and desc
[data-order-asc],
[data-order-desc] {
    color: $primary;
    font-size: 30px;
    height: 30px;
    width: 30px;
    transition: all $animation-duration ease-in-out;
    cursor: pointer;

    &[data-order-asc="false"],
    &[data-order-desc="false"] {
        color: $grey;
    }
}
[data-order-asc] {
    transform: rotate(180deg);
    margin-right: -8px;
}

// Have to style plotly component content here to prevent component enclosure from breaking css selector
.map-graph-wrapper plotly-plot g.cartesianlayer .bars .points .point path {
    clip-path: inset(0% round 2px);
}
app-overview-plots .swiper-slide plotly-plot g.cartesianlayer .bars .points .point path {
    clip-path: inset(0% round 2px);
}

@for $i from 1 through 12 {
    .col-#{$i},
    .col-lg-#{$i},
    .col-xl-#{$i} {
        box-sizing: border-box;
        &:not(:first-of-type) {
            padding-left: 16px;
        }
        &:not(:last-of-type) {
            padding-right: 16px;
        }
    }

    .col-#{$i} {
        width: calc(100% * (#{$i} / 12));
    }
}

@for $i from 1 through 12 {
    .col-lg-#{$i} {
        @media screen and (min-width: $bp--lg) {
            width: calc(100% * (#{$i} / 12));
        }
    }
}
@for $i from 1 through 12 {  
    .col-xl-#{$i} {
        @media screen and (min-width: $bp--xl) {
            width: calc(100% * (#{$i} / 12));
        }
    }
}
  

@media screen and (min-width: $bp--md) {
    .container {
        padding-left: 60px;
        padding-right: 60px;
    }
}

@media screen and (min-width: $bp--lg) {
    .container {
        padding-left: 83px;
        padding-right: 83px;
    }
}

.aggregation-marker {
    background-color: white;
    box-sizing: border-box;
    border-radius: 0 15px 15px 0;
    padding: 5px 10px 3px 0;
    text-align: right;
    font-size: 1.4rem;
}

.state-dot {
    display: block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.icon-box {
    height: 20px;
    width: 20px;
    color: white;
    padding: 2px 0px;
    text-align: center;
    border-radius: 3px;

    > * {
        font-size: 16px;
    }
}

.context-menu {
    transition: all .1s ease-in-out;
    position: fixed;
    background: $background;
    box-shadow: $box-shadow;
    padding: 0;
    border-radius: 6px;
    z-index: 1010;

    li {
        list-style: none;
        padding: 10px 15px;
        cursor: pointer;
        transition: all calc($animation-duration / 3) ease-in-out;

        &:hover {
            background-color: $background;
            filter: brightness(80%);
        }
    }
}
@keyframes skeleton {
    100% {
        transform: translateX(100%);
    }
}
