@import 'variables';
// base styles
@import "../../node_modules/ng-zorro-antd/style/index.min.css";
// components
@import "../../node_modules/ng-zorro-antd/button/style/index.min.css";
@import "../../node_modules/ng-zorro-antd/time-picker/style/index.min.css";
@import "../../node_modules/ng-zorro-antd/date-picker/style/index.min.css";
@import "../../node_modules/ng-zorro-antd/slider/style/index.min.css";

// overwrite antd styles
address, dl, ol, p, pre, ul {
    margin-top: inherit;
}
dl, h1, h2, h3, h4, h5, h6, ol, p, pre, ul {
    margin-bottom: inherit;
}
.cdk-overlay-container,
.cdk-overlay-connected-position-bounding-box,
.cdk-overlay-pane,
date-range-popup {
    z-index: 1015 !important;
}
// date picker
.ant-picker-panel-container {
    border: 1px double transparent;
    border-radius: 6px;
    background-image: $background-image;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    box-shadow: $box-shadow--panel;

    .ant-picker-panel {
        border: 1px double transparent;
        border-radius: 6px;
        background-color: $white;

        .ant-picker-header {
            color: $text;
            border-bottom: 1px solid $grey;

            button {
                color: $text;
            }
        }

        .ant-picker-content {

            th {
                color: $text;
            }

            .ant-picker-cell {
                color: $text;
                opacity: .5;
            }

            .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
                background: none;
            }

            .ant-picker-cell-in-view {
                &.ant-picker-cell-range-end, 
                &.ant-picker-cell-range-start, 
                &.ant-picker-cell-selected {
                    .ant-picker-cell-inner {
                        background: $primary;
                    }
                }
            }
    
            .ant-picker-cell-in-view, 
            li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
                color: $text;
                opacity: .9;
            }

            .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
                border: 1px solid $primary;
            }

            .ant-picker-cell-in-range::before,
            .ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
            .ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
                background: $primary--lighter;
            }

            .ant-picker-cell-in-range:hover::before,
            .ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):hover::before,
            .ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):hover::before,
            .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
            .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
                background: $primary--light;
            }

            .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
            .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
                background-color: transparent;
            }

            li.ant-picker-time-panel-cell-selected 
            .ant-picker-time-panel-cell-inner {
                background: $primary--lighter;
            }

            li.ant-picker-time-panel-cell 
            .ant-picker-time-panel-cell-inner:hover {
                background: $primary--light;
            }

            .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner {
                background: $white;
                filter: brightness(70%);
            }

        }

        .ant-picker-now {
            display: none;
        }

        .ant-picker-time-panel, .ant-picker-time-panel-column {
            border-left: 1px solid $grey;
        }

        .ant-picker-today-btn {
            color: $primary
        }
    }

    button {
        &:disabled {
            color: $grey;
            &:hover { color: $grey; }
        }
    }

    .ant-btn-primary {
        background: $primary;
        border-color: $primary;
        height: 30px;
        padding: 0 12px;
        font-size: 16px;
    
        &:hover {
            background: $primary--dark;
            border-color: $primary--dark;
        }
    }
}
.ant-picker-range-arrow {
    background: linear-gradient(135deg,transparent 40%, $white 40%);

    &::before {
        background: -10px -10px no-repeat $white;
    }
}
::selection {
    color: $text;
    background: #4587c460;
}

.ant-slider {
    .ant-slider-rail {
        background-color: $grey;
    }

    .ant-slider-track {
        background-color: $primary--light;
    }

    .ant-slider-handle {
        border-color: $primary--lighter;

        &:focus {
            border-color: $primary;
            box-shadow: 0 0 0 5px rgba($secondary--light, .12);
        }
    }

    &:hover {
        .ant-slider-rail {
            background-color: $grey;
        }

        .ant-slider-track {
            background-color: $primary--lighter;
        }

        .ant-slider-handle:not(.ant-tooltip-open) {
            border-color: $primary;
        }
    }
}
