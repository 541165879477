@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/MaterialIcons/MaterialIcons-Regular.ttf') format('truetype');
    font-display: block; // don't display text while loading
}

.material-icon {
    font-family: 'Material Icons' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    user-select: none;
}

// Bold
@font-face {
    font-family: "ChesnaGrotesk";
    font-style: normal;
    font-weight: 900;
    src: url('../assets/fonts/ChesnaGrotesk/ChesnaGrotesk-Bold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "ChesnaGrotesk";
    font-style: italic;
    font-weight: 900;
    src: url('../assets/fonts/ChesnaGrotesk/ChesnaGrotesk-BoldItalic.ttf') format('truetype');
    font-display: swap;
}

// Semi Bold
@font-face {
    font-family: "ChesnaGrotesk";
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/ChesnaGrotesk/ChesnaGrotesk-SemiBold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "ChesnaGrotesk";
    font-style: italic;
    font-weight: 700;
    src: url('../assets/fonts/ChesnaGrotesk/ChesnaGrotesk-SemiBoldItalic.ttf') format('truetype');
    font-display: swap;
}

// Medium
@font-face {
    font-family: "ChesnaGrotesk";
    font-style: normal;
    font-weight: 500;
    src: url('../assets/fonts/ChesnaGrotesk/ChesnaGrotesk-Medium.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "ChesnaGrotesk";
    font-style: italic;
    font-weight: 500;
    src: url('../assets/fonts/ChesnaGrotesk/ChesnaGrotesk-MediumItalic.ttf') format('truetype');
    font-display: swap;
}

// Regular
@font-face {
    font-family: "ChesnaGrotesk";
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/ChesnaGrotesk/ChesnaGrotesk-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "ChesnaGrotesk";
    font-style: italic;
    font-weight: 400;
    src: url('../assets/fonts/ChesnaGrotesk/ChesnaGrotesk-RegularItalic.ttf') format('truetype');
    font-display: swap;
}
