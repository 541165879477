@import 'variables';

.tooltip-container,
.tooltip-container-absolute {
    position: fixed;
    font-size: 1.8rem;
    color: $white;
    padding: 16px;
    text-align: center;
    z-index: 1010;
    width: auto;
    background: $text;
    border-radius: 4px;
    box-sizing: border-box;
    opacity: 0;
    pointer-events: none;
    white-space: nowrap;

    &::after {
        content: "";
        position: absolute;
        height: 10px;
        width: 10px;
        background: $text;
        transform: rotate(45deg);
    }

    animation-name: tooltip-slide-top;
    animation-duration: 0.16s;
    animation-fill-mode: forwards;
    animation-delay: .35s;

    &.tooltip-short-delay {
        animation-delay: .05s;
    }

    &.small {
        padding: 7px 10px;
        font-size: 1.4rem;
        max-width: 200px;
        white-space: normal;
    }

    &.to-top {
        &::after {
            left: calc(50% - 5px);
            bottom: -5px;
            transform: rotate(45deg);
        }
    }

    &.to-bottom {
        animation-name: tooltip-slide-bottom;

        &::after {
            left: calc(50% - 5px);
            top: -5px;
            transform: rotate(135deg);
        }
    }

    &.to-left {
        animation-name: tooltip-slide-left;

        &::after {
            top: calc(50% - 5px);
            right: -5px;
        }
    }

    &.to-right {
        animation-name: tooltip-slide-right;

        &::after {
            top: calc(50% - 5px);
            left: -5px;
            transform: rotate(-45deg);
        }
    }
}

.tooltip-container-absolute {
    position: absolute;
}

.echarts-event-tooltip,
.echarts-event-tooltip-medium,
.echarts-event-tooltip-small {
    box-shadow: $box-shadow--panel !important;
    width: 240px;
    color: #475569;
    z-index: 1000 !important;

    &:not(:empty) {
        border: 1px double transparent;
        border-radius: 6px !important;
        background-image: $background-image;
        background-origin: border-box;
        background-clip: padding-box, border-box;
    }

    &.echarts-event-tooltip-medium {
        width: 200px;
    }

    &.echarts-event-tooltip-small {
        width: 170px;
    }

    .sections-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .section {
            word-break: break-all;
            white-space: normal;
            padding-left: 8px;
            padding-right: 8px;
            width: 100%;

            &.col {
                width: 50%;
            }

            padding-top: 8px;
            &:not(:first-of-type) {
                padding-top: 4px;
            }

            &:not(:last-of-type) {
                padding-bottom: 4px;
            }

            p {
                padding: 0;
                line-height: 1.3;
            }

            .headline {
                font-size: 14px;
                font-weight: bold;
                line-height: 1;
            }

            .info {
                font-size: 10px;
                font-weight: bold;
                color: #94A3B8;
            }
            
            .pre {
                font-size: 10px;
                font-weight: bold;
                color: #C4C4C4;
                white-space: nowrap;
            }
        
            .main {
                font-size: 12px;
                font-weight: bold;
            }
        
            .sub {
                font-size: 12px;
                color: #94A3B8;
            }

            .use-for-defect {
                display: flex;
                gap: 4px;
                font-weight: bold;
                line-height: 18px;
                cursor: pointer;

                .material-icon {
                    font-size: 16px;
                }
            }
            
            .state-pill {
                color: white;
                font-size: 12px;
                display: inline-block;
                padding: 0px 8.5px 0px 8px;
                border-radius: 15px;
                text-align: center;
                margin: 3px 0px;
            }
        }
    }

    .filter-buttons-container {
        .chart-filter-button {
            background: none;
            border-width: 0 0 1px 0;
            border-bottom: 1px solid #C4C4C4;
            cursor: pointer;
            font-size: 1.2rem;
            line-height: 2;
            color: #475569;
            transition: all calc($animation-duration / 2) ease-in-out;

            * {
                pointer-events: none;
            }

            &:not(:first-of-type) {
                margin-left: -3px;
            }

            .filter-dot {
                display: inline-block;
                height: 15px;
                width: 15px;
                border-radius: 50%;
                margin-right: 4px;
                vertical-align: sub;
            }

            &.active {
                border-bottom: 1px solid #475569;
                
                .filter-dot {
                    border: 1.5px solid #475569;
                }
            }
        }
    }

    .scroll-overflow-container {
        overflow-x: auto;
        
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 4px;
            height: 4px;
        }
        &::-webkit-scrollbar-track {
            width: 4px;
            height: 4px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: rgba(0, 0, 0, .3);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .3);
        }

        scrollbar-width: 4px;

        &.scroll-padding {
            padding: 8px 0 7px 0;
        }

        .chart-event-button {
            margin-right: 8px;
            height: 24px;
            width: 24px;
            border-radius: 6px;
            padding: 0;
            border: 2px solid transparent;
            color: white;
            font-family: 'Material Icons' !important;
            font-weight: normal;
            font-style: normal;
            font-size: 16px;
            line-height: 1;
            cursor: pointer;
            box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.15);
            transition: all calc($animation-duration / 2) ease-in-out;

            &.active {
                border: 2px solid black;
            }
        }
    }
}

@keyframes tooltip-slide-top {
    0% {
        opacity: 0;
        transform: translate(-50%, 30%);
    }

    100% {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}

@keyframes tooltip-slide-bottom {
    0% {
        opacity: 0;
        transform: translate(-50%, -30%);
    }

    100% {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}

@keyframes tooltip-slide-left {
    0% {
        opacity: 0;
        transform: translate(-30%, 0);
    }

    100% {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}

@keyframes tooltip-slide-right {
    0% {
        opacity: 0;
        transform: translate(-70%, 0);
    }

    100% {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}
