@import 'variables';
@font-face {
    font-family: 'charging-icons';
    src: url('../assets/fonts/charging-icons.eot');
    src: url('../assets/fonts/charging-icons.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/charging-icons.woff') format('woff'),
         url('../assets/fonts/charging-icons.ttf') format('truetype'),
         url('../assets/fonts/charging-icons.svg#charging-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

// overwrites font in plots with mode = text to use custom icon font
// currently support for charging session icons: F = "Failure", R = "Regular"
.eon-icon-plot {
    .textpoint {
        font-family: "charging-icons" !important;
        transform: translate(0, 14px);
        
        * {
            font-family: "charging-icons" !important;
        }
    }
}

.main-svg {

    .ytick, .y2tick, .y3tick, .y4tick,
    .xtick, .x2tick, .x3tick, .x4tick {
        text {
            fill: $text !important;
        }
    }

    .infolayer text {
        fill: $text !important;
    }

    .layer-below .shapelayer path {
        stroke: $white !important;
        fill: $white !important;
    }
}

// kpi plots
.plot-box {
    .xtick, .ytick {
        text {
            fill: $text !important;
            font-size: 16px !important;
            opacity: .7 !important;
            filter: brightness(1.5) !important;
        }
    }
}

.updatemenu-item-rect {
    opacity: 0;
}
