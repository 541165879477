@import '../styles/variables';

// - - - - - Styling for Custom Columns (Reworked & Combined) - - - - - //

// general global styling utils
.num-circle {
    display: inline-block;
    text-align: center;
    font-size: 14px;
    line-height: 1.4;
    font-weight: 700;
    border-radius: 22px;
    background-color: $status--failure;
    color: white;
    height: 22px;
    min-width: 22px;
    padding: 1px 5px;
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.18);

    span {
        display: inline-block;
    }
}

// Station & Overall State
.column-overallstate,
.column-station {
    span.station-id {
        font-weight: 500;
        display: inline-block;
        margin-top: 2px;
        margin-bottom: 4px;
        padding-bottom: 2px;
    }
    span.state {
        padding: 1px 8px 2px;
        line-height: 1.2;
        color: white;
        border-radius: 100px;
        white-space: nowrap;
    }
}
// AI Prediction
.column-aiprediction {
    font-weight: 500;
    span {
        display: flex;
        align-items: center;
        gap: 4px;
        .material-icon {
            font-size: 20px;
        }
    }
}
// Connector
.column-connector {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33px;
    width: 33px;
    margin-left: 2px;
    position: relative;

    .conn, .station {
        background-color: $grey--strong;
        height: 100%;
        width: 100%;
        mask-position: center;
        mask-repeat: no-repeat;
    }

    .conn {
        mask-size: 27px 27px;
        mask-image: url('../assets/icons/connector-inactive.svg');
    }

    .station {
        mask-size: 33px 33px;
        mask-image: url('../assets/icons/charging-station-turqouise.svg');
    }

    .num {
        color: $grey--strong;
        position: absolute;
        left: 3px;
        // set to mask size
        width: 27px;
        text-align: center;
    }
}
// Device & Location
.column-device,
.column-location {
    span {
        display: inline-block;
        margin-top: 2px;
        margin-bottom: 2px;
        &:nth-of-type(1) {
            font-weight: 500;
        }
        &:nth-of-type(2) {
            color: $grey--strong;
        }
    }
}
// E-Mail
.column-email {
    display: flex;
    align-items: center;
    gap: 4px;
    width: max-content;

    .is-user {
        color: $primary;
    }
}
// Two Values
.column-twovalues {
    span {
        display: inline-block;
        margin-top: 2px;
        margin-bottom: 2px;
        white-space: nowrap;
        &:nth-of-type(2) {
            color: $grey--strong;
        }
    }
}
// SIM Info
.column-siminfo {
    span {
        display: inline-block;
        margin-top: 2px;
        margin-bottom: 2px;
        white-space: nowrap;
        &.iccid {
            color: $grey--strong;
        }
    }
}
// OCPP Status
.column-ocppstatus {
    span {
        display: inline-block;
        margin-top: 2px;
        margin-bottom: 2px;
    }
}
// Last Error (State Cause)
.column-lasterror,
.column-lasterrorstatecause {
    span {
        display: inline-block;
        margin-top: 2px;
        margin-bottom: 2px;
    }
}
// Last Message & Last OK Session
.column-lastmessage,
.column-lastoksession {
    span {
        display: inline-block;
        margin-top: 2px;
        margin-bottom: 2px;
        &:nth-of-type(1) {
            font-weight: 400;
        }
        &:nth-of-type(2) {
            color: $grey--strong;
        }
    }
}
// Last Restart
.column-lastrestart {
    span {
        display: inline-block;
        margin-top: 2px;
        margin-bottom: 2px;
    }
    .failed {
        font-weight: 500;
    }
    span:nth-of-type(2) {
        color: $grey--strong;
    }
}
// Last Session
.column-lastsession {
    span {font-weight: 500}
}
// Recommendation 
.column-recommendation {
    display: flex;
    align-items: center;
    .material-icon {
        color: $text;
        font-size: 32px;
        margin-right: 8px;
    }
    .recommendation-info {
        display: flex;
        flex-direction: column;
        span {
            margin-top: 2px;
            margin-bottom: 2px;
            &:nth-of-type(1) {font-weight: 500}
            &:nth-of-type(2) {filter: brightness(2)}
        }
        .null {
            display: none;
        }
    }
    .icon-clear,
    .icon-done {
        filter: brightness(2);
        span:nth-of-type(2) {filter: brightness(1.5)}
    }
}
// Restart Disabled
.column-restart-disabled {
    height: 66px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
}
// Service Provider
.column-serviceprovider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .material-icon {
        font-size: 20px;
        padding: 0px 10px;
        color: $text !important;
        &.disabled {
            color: $grey !important;
            pointer-events: none;
        }
    }
}
// Sessions 24 h & Sessions 2 W
.column-sessions {
    font-weight: 500;
    .ok, .zero, .short, .sep {
        display: inline-block;
        margin-top: 2px;
        margin-bottom: 2px;
    }
    .zero, .short, .sep {
        font-weight: 400;
    }
}
// Current Ticket
.column-currentticket {
    span {
        display: inline-block;
        margin-top: 2px;
        margin-bottom: 2px;
        &.open-title {font-weight: 500}
        &.closed-title,
        &.open-date {color: $grey--strong;}
        &.closed-date {color: $grey;}
    }
}
// Tickets
.column-tickets {
    span {
        display: inline-block;
        margin-top: 2px;
        margin-bottom: 2px;
        &.closed {
            color: $grey--strong;
        }
    }
}
// Indicators
.column-indicators {
    display: flex;
    gap: 8px;
    span {font-size: 20px}
}

// State
.column-state-users {
    display: flex;
    justify-content: flex-start;
    span {font-size: 19px}
    .active {color: $status--ok}
    .inactive {color: $status--no-data}
    .invited {color: $status--to-be-monitored}
    .invite-expired {color: $status--failure}
}
// Roles
.column-roles {
    display: flex;
    justify-content: flex-start;

    span {
        font-size: 19px;
        color: $grey;
        padding-left: 2.5px;
        padding-right: 2.5px;
    }

    .active {
        color: $primary;
    }
}
// Invited & Joined
.column-invitestate {
    span {
        color: $text--light;
    }
}
// Tenant Groups
.column-array {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    .bubble {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px;
        height: 22px;
        width: fit-content;
        background-color: $primary;
        color: white;
        border-radius: 12px;
        font-size: 14px;
        word-break: keep-all;
        white-space: nowrap;

        &.disabled {
            opacity: .7;
        }
    }
    .no-break {
        padding: 5px 10px;
        white-space: nowrap;
    }
}

// - - - - Config Panel - - - - //

.column-configkey {
    text-align: right;
}

// - - - - Ticket Table - - - - //

.column-lastchange, 
.column-opentime {
    .acc-dark {
        color: $grey--strong;
    }
    .acc-light {
        color: $grey;
    }
}

.column-state-tickets {
    .closed {
        color: $grey;
    }
}

.column-title {
    font-weight: bold;
}

// - - - - Filter Sets - - - - //
.column-created,
.column-modified {
    color: $grey--strong;
}

// - - - - Errors - - - - //

.column-error {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
        font-weight: bold;
    }
}

.column-fixableby {
    display: flex;
    align-items: center;
    gap: 8px;
    width: min-content;
    padding: 3px;
    
    span {
        background-color: $text;
    }
}

.column-autorestart {
    display: flex;
    align-items: center;
}

.column-errormodelinfluence {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info > span {
        display: inline-block;
        margin-top: 2px;
        margin-bottom: 2px;
        padding: 1px 8px;
        color: white;
        border-radius: 100px;
        white-space: nowrap;

        &.empty {
            color: $text;
        }
    }

    &.errormodelinfluence-detailed {
        display: grid;
        grid-template-columns: 65px 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 3px;

        .state-pill {
            display: inline-block;
            margin-top: 2px;
            margin-bottom: 2px;
            padding: 1px 8px;
            color: white;
            border-radius: 100px;
            white-space: nowrap;
        }
    }

    .material-icon {
        font-size: 20px;
        padding: 0px 10px;
        color: $text !important;
    }
}

.column-errorinsights {
    .icon-box {
        background: $text;
        font-size: 16px;
    }

    .no-word-break {
        font-weight: 500;
    }
}

// - - - - Notifications - - - - //

.column-category {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65px;

    .material-icon {
        &.update {
            color: $primary
        }
        &.information {
            color: $text;
        }
        &.warning {
            color: $status--to-be-monitored;
        }
        &.error {
            color: $status--failure;
        }
    }
}

.column-activated,
.column-persistent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65px;

    .material-icon {
        &.true {
            color: $status--ok
        }
        &.false {
            color: $status--failure;
        }
    }
}
