@import '../styles/variables';

.plot-row {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .plot-info {
        width: 45px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        // fixed dimensions only for dev
        &.reduced-height {
            height: 45px;
        }

        &.reduced-width {
            width: 39px;
        }

        .connector-id {
            position: relative;
            height: 23px;
            width: 27px;
            color: $grey--strong;
            font-size: 1.2rem;
            font-weight: bold;
            text-align: center;
            line-height: 2.2rem;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                mask-size: 27px 23px;
                background-color: $grey;
                mask-image: url('/assets/icons/connector-inactive.svg');
                mask-position: center;
                mask-repeat: no-repeat;
            }
        }

        .current-type {
            font-size: 1.2rem;
            line-height: 2;
            font-weight: bold;
            color: $grey--strong;
        }

        .station-icon {
            height: 32px;
            width: 32px;
            background-color: $grey--strong;
            mask-image: url('/assets/icons/charging-station-turqouise.svg');
            mask-size: 25px 32px;
            mask-position: center;
            mask-repeat: no-repeat;
        }
    }

    .plot-wrapper {
        width: 100%;
    }
}
